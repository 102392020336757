import request from '../utils/request';

export const fetchData = query => {
    return request({
        url: '/wechat/getAll',
        method: 'get',
        params: query
    });
};

export const qrcode = query => {
    return request({
        url: '/api/qrcode',
        method: 'get',
        params: query
    });
};
export const check = query => {
    return request({
        url: '/api/check',
        method: 'post',
        data: query
    });
};
export const userinfo = query => {
    return request({
        url: '/api/userinfo',
        method: 'get',
        params: query
    });
};
export const jdSetInfo = query => {
    return request({
        url: '/wechat/jd/setInfo',
        method: 'post',
        data: query
    });
};

export const jdApi = query =>{
    return request({
        url: 'https://plogin.m.jd.com/cgi-bin/mm/new_login_entrance?lang=chs&appid=300&returnurl=https://wq.jd.com/passport/LoginRedirect?state=${Date.now()}&returnurl=https://home.m.jd.com/myJd/newhome.action?sceneval=2&ufc=&/myJd/home.action&source=wq_passport',
        data: query,
        header : {
          Connection: 'Keep-Alive',
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json, text/plain, */*',
          'Accept-Language': 'zh-cn',
          Referer: 'https://plogin.m.jd.com/cgi-bin/mm/new_login_entrance?lang=chs&appid=300&returnurl=https://wq.jd.com/passport/LoginRedirect?state=${Date.now()}&returnurl=https://home.m.jd.com/myJd/newhome.action?sceneval=2&ufc=&/myJd/home.action&source=wq_passport',
          'User-Agent': "JD4iPhone/10.0.10 CFNetwork/1206 Darwin/20.1.0",
          Host: 'plogin.m.jd.com',
        },
    });
};
