import {createRouter, createWebHistory} from "vue-router";
const routes = [
    {
        path: '/',
        redirect: '/zdm'
    }, {
        path: "/zdm",
        name: "zdm",
        meta: {
            title: '张大妈'
        },
        component: () => import (
        /* webpackChunkName: "login" */
        "../views/HelloWorld.vue")
    }, {
        path: "/douban",
        name: "douban",
        meta: {
            title: '豆瓣'
        },
        component: () => import (
        /* webpackChunkName: "login" */
        "../views/Douban.vue")
    }, {
        path: "/live",
        name: "live",
        meta: {
            title: 'live'
        },
        component: () => import (
            /* webpackChunkName: "login" */
            "../views/live.vue")
    }, {
        path: "/jdCookie",
        name: "jdCookie",
        meta: {
            title: '京东Cookie'
        },
        component: () => import (
            /* webpackChunkName: "login" */
            "../views/jdCookie.vue")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next();
});

export default router;
