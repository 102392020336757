<template>
  <el-scrollbar style="height: 100%">
    <div id="show_key_word">
      <el-row>
        <el-col align="center" span="15" v-for="(o) in key_words" :key="o">
          <el-card :body-style="{ padding: '0px' }">

            <a :href="o.pageUrl">
              <img style="height: 50%;width: 50%" :src="o.link"  class="image"/>
              <h3>{{o.mall}}</h3>
            </a>
            <div style="padding: 14px;">
              <span>{{o.title}}</span><br/>
              <!--<span>{{o.price}}</span><br/>-->
              <div class="bottom">
                <time class="time">{{ currentDate }}</time>
                <span>喜爱:{{o.worthy}}   评论数:{{o.commentNum}}</span>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </el-scrollbar>
</template>

<script>
import {fetchData} from '../api/index'

export default {
  name:"hello",
  methods: {
    loadData() {
      let isLoading = false
      let bottomOfWindow = this.getScrollTop() + this.getWindowHeight() === this.getScrollHeight()
      const scrollheight = this.getScrollTop()
      if(bottomOfWindow && !isLoading) {
        isLoading = true
        this.loading = true
        isLoading = this.loadingData()
        // 加载完数据之后将滚动条回滚到没加载的时候的位置
        window.scrollTo(100,scrollheight)
      }
    },
    loadingData(){
      fetchData({'type':'douban'})
          .then(response => {
            if (response.body) {
              for(let i of response.body) {
                i.url = i.link;
                this.key_words.push(i)
              }
            }
            this.loading = false
          })
          .catch(err => {
            console.log(err)
          })
      console.log("数据加载完成！douban")
    },

    getScrollTop(){
      let scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
      if(document.body){
        bodyScrollTop = document.body.scrollTop;
      }
      if(document.documentElement){
        documentScrollTop = document.documentElement.scrollTop;
      }
      scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
      return scrollTop;
    },

    getScrollHeight(){
      let scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
      if(document.body){
        bodyScrollHeight = document.body.scrollHeight;
      }
      if(document.documentElement){
        documentScrollHeight = document.documentElement.scrollHeight;
      }
      scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
      return scrollHeight;
    },

    getWindowHeight(){
      let windowHeight = 0;
      if(document.compatMode == "CSS1Compat"){
        windowHeight = document.documentElement.clientHeight;
      }else{
        windowHeight = document.body.clientHeight;
      }
      return windowHeight;
    }
  },
  mounted() {
    this.loadData()
  },
  data() {
    return {
      key_words: [],
      loading:false
    }
  }
}
</script>

<style scoped>
#show_key_word {
  margin-left: 10%;
  margin-right: 10%
}
</style>

<!--

<template>
  <el-scrollbar style="height: 100%">
  <el-row>
    <el-col align="center" span="15" v-for="(o) in data_list" :key="o">
      <el-card :body-style="{ padding: '0px' }">

        <a :href="o.pageUrl">
        <img style="height: 50%;width: 50%" :src="o.link"  class="image"/>
          <h3>{{o.mall}}</h3>
        </a>
        <div style="padding: 14px;">
          <span>{{o.title}}</span><br/>
          <span>{{o.price}}</span><br/>
          <span>{{o.mall}}</span>
          <div class="bottom">
            <time class="time">{{ currentDate }}</time>
            <el-button type="text" class="button">操作按钮</el-button>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
  </el-scrollbar>
</template>

<script>
import {fetchData} from "../api/index"
export default {
  name: 'HelloWorld',
  data() {
    return {
      data_list : []
    }
  },
  props: {
    msg: String
  },
  created() {
    this.inita()
  },
  methods:{
    inita(){
      fetchData().then(res => {
        this.data_list = res.body
        console.log(res)
      })
    }
  }
}
</script>

&lt;!&ndash; Add "scoped" attribute to limit CSS to this component only &ndash;&gt;
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
<style scoped>
.el-carousel__item h3 {
  color: white;
  font-size: 0.8rem;
  opacity: 0.75;
  margin: 0;
}
.pic_item h3 {
  position: absolute;
  left: 1rem;
  bottom: 2rem;
}
</style>
-->
