
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import vueWaterfallEasy from 'vue-waterfall-easy'
import installElementPlus from './plugins/element'
import { ElButton, ElInput, ElMessage } from 'element-plus'
import 'element-plus/theme-chalk/base.css'


const plugins = [ElMessage]
const app = createApp(App)

plugins.forEach((plugin) => {
    app.use(plugin)
})

installElementPlus(app)
app.use(store).use(vueWaterfallEasy).use(router).mount('#app')
